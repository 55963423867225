.editablecARD {
	width: 377px !important;
	height: 599px;
	position: relative;
	padding: 0px;
}
input::placeholder {
	color: transparent;
}

.editablecARD > img {
	width: 377px;
	height: 599px;
}
.editableContent {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 377px !important;
	height: 599px;
	background-color: transparent;
	z-index: 1;
}
.editableContent > .upperhalf {
	width: 100%;
	height: 380px;
	background-color: transparent;
}
.editableContent > .upperhalf > .inputConatiners {
	width: 100%;
	height: calc(100% - 60px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	padding-top: 100px;
	padding-left: 25px;
}
.editableContent > .upperhalf > .nameContaiber {
	width: 100%;
	background-color: transparent;
	height: 60px;
	padding: 0px 54px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.verticalLine {
	width: 2px;
	border-radius: 2px;
	background-color: transparent;
	height: 120px;
}
.editableContent > .upperhalf > .nameContaiber > input:focus {
	caret-color: red !important;
}
.editableContent > .upperhalf > .nameContaiber > input {
	width: 100%;
	height: 100%;
	background-color: transparent;
	text-align: center;
	outline: none !important;
	border: 0px solid red !important;
	text-align: center;
	font-weight: bold;
	font-family: MYbold;
	color: transparent;
	font-size: 41px;
}
.editableContent > .upperhalf > .inputConatiners > .imgContainer {
	width: 200px;
	height: 100%;
	background-color: transparent;
	margin-left: 7px;
}
.editableContent > .upperhalf > .inputConatiners > .imgContainer > label {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.editableContent > .upperhalf > .inputConatiners > .imgContainer > label > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.editableContent > .upperhalf > .inputConatiners > .imgContainer > input {
	width: 100%;
	height: 100%;
	background-color: transparent !important;
	outline: none !important;
	border: 0px !important;
	text-align: center;
}
.editableContent > .upperhalf > .inputConatiners > .blackcontainer {
	width: 60px;
	height: 100%;
	background-color: transparent;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
}
.editableContent
	> .upperhalf
	> .inputConatiners
	> .blackcontainer
	> input:focus {
	caret-color: red !important;
}
.editableContent > .upperhalf > .inputConatiners > .blackcontainer > input {
	width: 100%;
	background-color: transparent !important;
	outline: none !important;
	border: 0px solid red !important;
	height: 35px;
	text-align: center;
	font-size: 25px;
	color: transparent;
	font-family: NormalFont;
}

.editableContent > .upperhalf > .inputConatiners > .blackcontainer > .smallImg {
	width: 100%;
	height: 50px;
	border: 0px solid red;
}
.editableContent
	> .upperhalf
	> .inputConatiners
	> .blackcontainer
	> .smallImg
	> img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.smallSelctors {
	width: 100%;
	background-color: transparent !important;
	outline: none !important;
	border: 0px !important;
	height: 100%;
	text-align: center;
	color: transparent;
}
.smallLinehh {
	width: 60%;
	height: 2px;
	background-color: transparent;
	border-radius: 2px;
	margin: 5px 0px;
}
.largelinh {
	width: 100%;
	height: 2px;
	background-color: transparent;
	border-radius: 2px;
	margin-top: 5px;
}
.smallSelctors > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.editableContent
	> .upperhalf
	> .inputConatiners
	> .blackcontainer
	> input:first-child {
	height: 60px;
	color: transparent;
	font-size: 42px;
	font-family: NormalFont;
}
.editableContent > .lowerhalf {
	width: 100%;
	height: 134px;
	background-color: transparent;
	padding: 7px 65px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: row;
}
.smallestLineh {
	width: 50px;
	height: 2px;
	border-radius: 2px;
	background-color: transparent;
}
.editableContent > .seclowehalf {
	width: 100%;
	height: 85px;
	background-color: transparent;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: row;
}
.editableContent > .lowerhalf > .leftinputs {
	width: 48%;
	background-color: transparent;
}
.editableContent > .lowerhalf > .leftinputs > .fieldsContainer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 5px;
}
.editableContent
	> .lowerhalf
	> .leftinputs:first-child
	> .fieldsContainer
	> input:first-child {
	margin-left: 10px;
}
.editableContent
	> .lowerhalf
	> .leftinputs:last-child
	> .fieldsContainer
	> input:first-child {
	margin-left: 3px;
}
.editableContent
	> .lowerhalf
	> .leftinputs:last-child
	> .fieldsContainer
	> input:last-child {
	margin-right: 25px;
}
.editableContent > .lowerhalf > .leftinputs > .fieldsContainer > input:focus {
	caret-color: red !important;
}
.editableContent > .lowerhalf > .leftinputs > .fieldsContainer > input {
	width: 40px;
	height: 40px;
	text-align: center;
	background-color: transparent !important;
	border: 0px solid red !important;
	outline: none !important;
	color: transparent;
	font-size: 20px;
	font-family: NormalFont;
}
.editableContent
	> .lowerhalf
	> .leftinputs
	> .fieldsContainer
	> input:first-child {
	font-family: MYbold;
}
@media screen and (max-width: 576px) {
	.editableContent {
		width: 270px !important;
		height: 400px;
	}
	.editablecARD > img {
		width: 270px;
		height: 400px;
	}

	.editableContent > .upperhalf {
		width: 100%;
		height: 250px;
	}
	.editableContent > .upperhalf > .inputConatiners {
		height: calc(100% - 40px);
		padding-top: 67px;
		padding-left: 20px;
	}
	.editableContent > .upperhalf > .nameContaiber {
		height: 40px;
		padding: 0px 40px;
	}
	.verticalLine {
		height: 100%;
		width: 1px;
	}
	.editableContent > .upperhalf > .nameContaiber > input {
		font-size: 29px;
		height: 100%;
	}
	.editableContent > .upperhalf > .inputConatiners > .imgContainer {
		width: 150px;
		margin-left: 4px;
	}

	.editableContent > .upperhalf > .inputConatiners > .blackcontainer {
		width: 40px;
	}
	.editableContent > .upperhalf > .inputConatiners > .blackcontainer > input {
		height: 30px;
		font-size: 17px;
	}

	.editableContent
		> .upperhalf
		> .inputConatiners
		> .blackcontainer
		> .smallImg {
		width: 100%;
		height: 30px;
	}
	.editableContent
		> .upperhalf
		> .inputConatiners
		> .blackcontainer
		> .smallImg
		> img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.smallLinehh {
		margin: 2.5px 0px;
		height: 1px;
	}
	.largelinh {
		margin-top: 3px;
		height: 1px;
	}
	.smallSelctors > svg {
		font-size: 27px;
		color: white;
	}
	.editableContent
		> .upperhalf
		> .inputConatiners
		> .blackcontainer
		> input:first-child {
		height: 35px;
		color: transparent;
		font-size: 28px;
	}
	.editableContent > .lowerhalf {
		width: 100%;
		height: 94px;
		padding: 3px 40px;
		padding-top: 5px;
	}

	.smallestLineh {
		width: 30px;
		height: 1px;
	}
	.editableContent > .seclowehalf {
		height: 53px;
	}

	.editableContent > .lowerhalf > .leftinputs > .fieldsContainer > input {
		height: 25px;
		width: 29px;
		font-size: 15px;
		margin-top: 3px;
	}
	.editableContent
		> .lowerhalf
		> .leftinputs:first-child
		> .fieldsContainer
		> input:first-child {
		margin-left: 0px;
	}
	.editableContent
		> .lowerhalf
		> .leftinputs:first-child
		> .fieldsContainer
		> input:last-child {
		margin-left: 2px;
	}
	.editableContent
		> .lowerhalf
		> .leftinputs:last-child
		> .fieldsContainer
		> input:first-child {
		margin-left: 7px;
	}
	.editableContent
		> .lowerhalf
		> .leftinputs:last-child
		> .fieldsContainer
		> input:last-child {
		margin-right: 0px;
		margin-left: 8px;
	}
}
