.firstContWrapper {
	background: transparent;
	height: max-content;
	position: relative;
	background: #cbfafc;
}
.newcolor1 {
	color: #cbfafc !important;
}
@media screen and (max-width: 576px) {
	.firstContWrapper {
		background: #cbfafc;
		background-image: url("../ownassets/firstsesion1.png");
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		mask-image: linear-gradient(to top, transparent, black, black);
	}
}
.newColor {
	color: #0e5053 !important;
}

.secondContainer .slick-list {
	overflow: visible !important;
}
.slick-list {
	overflow: hidden !important;
}
.slick-slider.slick-initialized {
	margin-top: 30px;
}
.slick-slider.slick-initialized > .slick-dots {
	margin-top: 30px;
}
.slick-track {
	display: flex !important;
}
.slick-active.slick-center.slick-current {
	transform: scale(1.2, 1.1);
	width: 320px;
	margin: 0px auto;
	align-self: center;
}
@media screen and (max-width: 285px) {
	.slick-active.slick-center.slick-current {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		margin-right: auto !important;
		margin-left: -25px !important;
		width: 180px !important;
		/* margin-left: -50px;
		margin-right: -40px; */
	}
}
.slick-active.slick-center.slick-current > div > div {
	width: 100% !important;
	z-index: 1.5;
	margin: 0px auto;
}
.firstCont {
	min-height: 40vh;
	max-height: max-content;
	overflow-x: hidden;
}

.firstCont img {
	height: 326px;
}

.secondContainer {
	background: #ffffff;
	min-height: 80vh;
	height: fit-content;
	padding-top: 30px;
	overflow-x: hidden;
}
.thirdContainer {
	background: rgba(206, 210, 220, 0.5);
	min-height: 60vh;
	height: fit-content;
	position: relative;
	overflow: visible;
	padding: 30px 0px;
	overflow-x: hidden;
}
.fourthContainer {
	background: rgba(248, 154, 68, 0.18);
	min-height: 40vh;
	padding-top: 40px;
	padding-bottom: 20px;
	height: fit-content;
	overflow: visible;
	overflow-x: hidden;
}
.fifthContainer {
	background: rgba(248, 154, 68, 0.18);
	min-height: 60vh;
	height: fit-content;
	overflow: visible;
	overflow-x: hidden;
}
.sixthContainer {
	background: rgba(206, 210, 220, 0.5);
	min-height: 60vh;
	height: fit-content;
	position: relative;
	overflow: visible;
	padding: 30px 0px;
	overflow-x: hidden;
}
.seventhContainer {
	background: white;
	min-height: 60vh;
	height: fit-content;
	overflow: visible;
	padding: 30px 0px;
	overflow-x: hidden;
}
.tenthContainer {
	background: rgba(248, 154, 68, 0.18);
	min-height: 60vh;
	height: fit-content;
	overflow: visible;
	padding: 30px 0px;
	margin-bottom: 30px;
	overflow-x: hidden;
}
.bottomLineDiv {
	width: 95%;
	max-width: 1000px;
	position: absolute;
	bottom: -20px;
	left: 0px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
	padding: 10px 0px;
	min-height: 50px;
	height: fit-content;
	border-radius: 5px;
	color: white;
}

.secondContainerCard {
	background: rgba(248, 154, 68, 0.18);
	height: 100%;
	min-height: 620px;
	width: 100%;
	max-width: 500px;
	border-radius: 24px;
	padding: 15px 0px;
}
.firstSectionHeading {
	font-size: 45px;
	font-weight: bold;
}
.newfirstSectionHeading {
	font-size: 30px;
	font-weight: bold;
}
.scrolledImageContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: fit-content;
}
.mainContainer {
	position: relative;
	padding: 0;
	min-width: 250px;
	min-height: 250px;
	display: inline-block;
	margin: 0 auto;
}

#myimage {
	border: none;
	max-width: 100%;
}

#textArea {
	display: block;
	padding: 10px 5px;
}

#theText {
	position: absolute;
	top: 90px;
	left: 0;
	background: #000;
	background: rgba(0, 0, 0, 0.1);
	color: #fff;
	width: auto;
	padding: 5px;
	text-align: left;
	border: dashed 2px #ff7f27;
	font: 15px Calibri;
	display: block;
	cursor: move;
}

canvas {
	max-width: 100%;
}
.respfootballContslider {
	width: 320px !important;
	height: 100%;
}
@media (max-width: 991.98px) {
	.firstSectionHeading {
		font-size: 30px;
		max-width: none;
	}
}

@media screen and (max-width: 768px) {
	.firstSectionHeading {
		font-size: 25px;
		max-width: none;
	}
	.resptextp {
		font-size: 0.67rem;
		max-width: 350px;
	}
	.secondContainerCard {
		border-radius: 0px !important;
		max-width: none !important;
	}
}
@media screen and (max-width: 767px) {
	.scrolledImageContainer {
		overflow-x: scroll;
	}
	.scrolledImageContainer img {
		height: 250px;
		width: 200% !important;
		object-fit: fill !important;
		margin-left: 250px;
		margin-right: 40px;
	}
}
.disableClass {
	opacity: 0.7;
	pointer-events: none;
}
@media screen and (max-width: 400px) {
	.respfootballContslider {
		width: 240px !important;
		height: 100%;
	}
	.scrolledImageContainer {
		overflow-x: scroll;
	}
	.scrolledImageContainer img {
		height: 250px;
		width: 200% !important;
		object-fit: fill !important;
		margin-left: 450px;
		margin-right: 40px;
	}
}
