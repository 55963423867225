.respiveLabel {
	font-size: 20px;
	font-weight: bold;
	width: 40px !important;
	height: 40px;
	border-radius: 30px;
}
.respQuestionHeading {
	font-size: 16px;
	font-weight: 600;
}
.inputLabelResp {
	font-size: 16px;
	font-weight: 500;
	color: #21325e;
	margin-top: 30px;
}
.inputCustmResp {
	height: 40px;
	width: 100%;
	border: 1px solid #21325e;
	background: white;
	border-radius: 5px;
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 0px 15px;
}
.CustHeadingRespComp {
	font-size: 28px;
	font-weight: bold;
}
.CustSubHeadingRespComp {
	font-size: 16px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.5);
}
.imgInptCustmResp {
	background: white;
	border: 1px solid #21325e;
	border-radius: 5px;
	height: 70px;
	margin-bottom: 30px;
}

.smallBtnsContShow .smallBtnsSettings {
	height: 40px;
	cursor: pointer;
	width: 100%;
	font-size: 12px;
	border: 1px solid #21325e;
	border-radius: 5px;
	background: white;
	padding: 0px 10px;
	font-weight: 600;
	color: #21325e !important;
}

.imgInptCustmResp > img,
.imgInptCustmResp > svg {
	font-size: 26px;
	margin-left: 5px;
	color: #21325e !important;
}
.smallBtnsContShow .smallBtnsSettings svg {
	font-size: 14px;
	margin-left: 5px;
	color: #21325e !important;
}

.imgInptCustmResp2 {
	background-color: white;
	height: 120px;
	width: 100%;
	margin-bottom: 30px;
}
.imgInptCustmResp2 .imgInptCustmResp {
	margin-bottom: 0px !important;
	width: 150px;
}
.imgInptCustmResp2 img {
	width: 150px;
	height: 120px;
	object-fit: contain;
}
.bellTextResp {
	font-size: 14px;
	font-weight: 400;
	color: red;
}
.customOptionSelector {
	color: rgba(0, 0, 0, 0.5);
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	cursor: pointer;
	padding-bottom: 10px;
}
.actieOption {
	border-bottom: 3px solid #21325e;
	color: #21325e;
}
.customOptionSmal {
	cursor: pointer;
	width: fit-content !important;
	margin-left: 10px;
	margin-bottom: 10px;
	padding: 5px 10px !important;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}
.customOptionCircle {
	width: 20px;
	height: 20px;
	border: 1px solid rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	margin-right: 10px;
}
.customOptionCircle img {
	width: 20px;
	height: 100%;
	object-fit: cover;
	vertical-align: unset !important;
}

.AttributeInputCust {
	width: 45% !important;
	height: 40px;
	background: white;
	text-align: center;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 600;
	margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}
.smalltextdivRating {
	width: 45px;
	height: 40px;
	background: white;
	text-align: center;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}
.largerRatingDiv {
	width: 100%;
	height: 40px;
	background: white;
	text-align: center;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media screen and (max-width: 768px) {
	.imgInptCustmResp2 img {
		width: 40%;
	}
	.imgInptCustmResp2 .imgInptCustmResp {
		width: 45%;
		font-size: 7px;
		justify-content: center;
	}
	.AttributeInputCust {
		height: 30px !important;
		font-size: 14px;
	}
	.customOptionCircle {
		width: 15px;
		height: 15px;
		margin-right: 5px;
	}
	.customOptionCircle img {
		width: 15px;
		height: 15px;
	}
	.customOptionSmal {
		font-size: 11px;
		padding: 3px 6px !important;
	}
	.customOptionSelector {
		font-size: 12px;
	}
	.bellTextResp {
		font-size: 9px;
	}
	.CustSubHeadingRespComp {
		font-size: 12px;
	}
	.CustHeadingRespComp {
		font-size: 22px;
	}
	.imgInptCustmResp {
		margin-bottom: 15;
		height: 45px;
	}
	.inputCustmResp {
		margin-top: 5px;
		margin-bottom: 10px;
		height: 30px;
		padding: 0px 10px;
	}
	.inputLabelResp {
		font-size: 13px;
		margin-top: 15px;
	}
	.respiveLabel {
		width: 28px !important;
		height: 28px;
		font-size: 14px;
	}
	.respQuestionHeading {
		font-size: 14px;
	}
}
