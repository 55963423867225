.wholeSection2 {
	padding: 2rem 4rem;
	min-height: 50vh;
	height: max-content;
	background: #21325e;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
}
.wholeSection2::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(217, 217, 217, 0.2);
	clip-path: circle(10% at 5% 90%);
}

.wholeSection2::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	background: rgba(217, 217, 217, 0.4);
	clip-path: circle(10% at 95% 10%);
}
.footerlinks {
	width: 100%;
	color: white !important;
	text-decoration: none !important;
	margin-bottom: 20px;
	font-size: 18px;
	font-weight: 700px !important;
}
.marginIssue {
	margin: 20px 0px;
}
@media screen and (max-width: 768px) {
	.widthissue {
		width: 100% !important;
	}
	.marginIssue {
		margin-top: 15px;
		margin-bottom: 10px;
		margin-left: 0px;
		margin-right: 0px;
	}
	.footerlinks {
		text-align: center;
		font-size: 12px;
		margin-bottom: 10px;
	}
}
