* {
	margin: 0px;
	padding: 0px;
	scroll-behavior: smooth;
}
.boxShadowDiv {
	box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.55);
}
.width90 {
	width: 90% !important;
	height: 40px;
	background: white;
	text-align: center;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 600;
	margin: 0 auto;
}
body {
	margin: 0;
	padding: 0px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}
.slick-next:before {
	content: "" !important;
}
.slick-prev:before {
	content: "" !important;
}
.slick-next {
	right: -17px !important;
}
.slick-prev {
	left: -17px !important;
}
.slick-dots {
	position: unset !important;
}
.slick-prev,
.slick-next {
	background-color: white !important;
	border-radius: 50px;
	z-index: 1;
	width: 30px !important;
	height: 30px !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.mainColor {
	color: #21325e !important;
}
.adsContainerdiv {
	width: 100%;
	height: 100vh !important;
	min-width: 250px;
	margin: 0px auto;
}
.modal {
	--bs-modal-zindex: 4000 !important;
}
.modal-open {
	z-index: 4000;
}
.modal-fullscreen .modal-content {
	background: transparent !important;
}

.mainColoerdDiv {
	background: #fdcb00;
	width: 100%;
	height: 100%;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	text-transform: capitalize;
	@media screen and (max-width: 374px) {
		font-size: 12px;
	}
}

.shwoadscardsadsdiv {
	height: 100% !important;
	width: 100%;
	z-index: 4000;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
}
.nonColor {
	color: gray !important;
}
.secondaryColor {
	color: #f1d00a !important;
}
.allCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}
.navbar-toggler {
	box-shadow: none !important;
	border: 0px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin-right: 20px;
}
.mainbg {
	background-color: #21325e !important;
}
.rightMarginResp {
	margin-right: 10px;
	@media screen and (max-width: 374px) {
		margin-right: 10px;
	}
}
.responsiveElements {
	font-size: 30px;
	@media screen and (max-width: 374px) {
		font-size: 22px;
	}
}

.offcanvas.offcanvas.offcanvas-end:first-child {
	flex-grow: 0.7 !important;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
}
.offcanvas.offcanvas.offcanvas-end:last-child {
	flex-grow: 0.1 !important;
}
.logoImgResp {
	width: 150px;
	@media screen and (max-width: 991.98px) {
		width: 130px;
	}

	@media screen and (max-width: 374px) {
		width: 100px;
	}
	@media screen and (max-width: 374px) {
		width: 100px;
	}
}
.doneIcondic {
	width: 56px;
	height: 56px;
	border-radius: 100%;
	background-color: #00b67a1a;
	display: flex;
	align-items: center;
	justify-content: center;
}
.doneIcondic > svg {
	color: green !important;
	font-size: 30px;
}
.modalCheckingbtn {
	font-size: 18px !important;
	margin-top: 10px;
	padding: 10px 20px !important;
	max-width: 250px;
	width: 100%;
	border-radius: 20px !important;
}
.modalHeading {
	text-align: center;
	font-size: calc(24px + 4vh) !important;
}
.thirdbg {
	background-color: #f89a44 !important;
}
.thirdClr {
	color: #f89a44 !important;
}
a {
	text-decoration: none !important;
	color: black !important;
}
.secondarybg {
	background: #f1d00a !important;
}
.lightSecondarybg {
	background-color: rgba(#f1d00a, 0.7);
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
@font-face {
	font-family: "MYbold";
	src: local("DINPro-CondensedBold"),
		url("./fonsts/DINPro-CondensedBold.ttf") format("truetype");
}
@font-face {
	font-family: "NormalFont";
	src: local("DINPro-CondensedMedium"),
		url("./fonsts/DINPro-CondensedMedium.otf") format("opentype");
}
p,
span,
a,
h1,
h2,
h3,
h4,
h4,
h6 {
	text-transform: none;
}
.active {
	color: #f89a44 !important;
}
.nav-link:hover {
	color: #21325e;
	transform: scale(1.2);
}
.active > svg {
	color: #f89a44 !important;
}
.slick-dots .slick-active {
	width: 10px !important;
}
.slick-dots li {
	margin: 0px !important;
}
.extraserviceheadresp {
	font-size: 16px;
	font-weight: 600px;
}
.slick-dots li.slick-active button:before {
	width: 7px !important;
	height: 7px;
	content: "";
	top: 6px;
	border-radius: 20px;
	background-color: black;
}
.responsiveQuestion {
	font-weight: bold;
	line-height: 50px;
	font-size: 20px;
}
.responsiveQuestion2 {
	font-weight: bold;
	font-size: 18px;
	line-height: 30px;
	text-transform: none !important;
}
.responsiveHeading {
	font-size: 50px;
	font-weight: bold;
}
.searhedCard {
	width: 100%;
	min-height: 60px;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	overflow: hidden;
	margin-bottom: 10px;
	border: 0px;
	padding: 10px 0px;
	border-bottom: 1px solid lightgray;
	cursor: pointer;
}
.nillbtn {
	box-shadow: none !important;
	--bs-btn-focus-box-shadow: none !important;
	border: 0px !important;
}
.topHeadingDiv {
	width: 100%;
	min-height: 10vh;
	height: fit-content;
	background: rgba(248, 154, 68, 0.18);
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
}
@media screen and (max-width: 768px) {
	.topHeadingDiv {
		padding: 0px 20px;
		min-height: 6vh;
		margin-bottom: 0px;
	}
}
.modalContainer {
	width: 100%;
	min-height: 100%;
	height: max-content;
	background: rgba(0, 0, 0, 0.8) !important;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	z-index: 4000;
	align-items: center;
	justify-content: center;
}
.offcanvas.offcanvas-end.show {
	z-index: 5000;
}
.sticky-top {
	top: 40px !important;
}
.closeBtnn {
	position: absolute;
	top: 20px;
	right: 20px;
	background-color: lightgray !important;
}
.deliveryFormContainer {
	width: 100%;
	max-width: 450px;
	height: fit-content;
}
.deliveryFormContainer .customForm {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	margin-top: 5px;
	margin-bottom: 30px;
}
.deliveryFormContainer .customForm div {
	margin-bottom: 20px;
	width: 100%;
}
.deliveryFormContainer .customForm div label {
	color: #21325e;
	width: 100%;
	font-weight: bold;
	margin-bottom: 5px;
}
.deliveryFormContainer .customForm div input {
	width: 100%;
	height: 50px;
	border: 2px solid #21325e;
	border-radius: 5px;
	padding: 0px 14px;
	outline: none !important;
	color: #21325e;
}
.searhinputtt {
	width: 100%;
	height: 50px;
	border: 2px solid #21325e;
	border-radius: 5px;
	padding: 0px 14px;
	outline: none !important;
	color: #21325e;
	margin-top: 15px;
}
.scrolledDivCards {
	width: 100%;
	display: grid;
	grid-template-columns: 320px 320px 240px;
	overflow-y: hidden;
	overflow-x: auto;
}
.deliveryFormContainer .customForm div textarea {
	width: 100%;
	height: 120px;
	border: 2px solid #21325e;
	border-radius: 5px;
	padding: 0px 14px;
	outline: none !important;
	color: #21325e;
}
.deliveryFormContainer .customForm button {
	width: 100%;
	max-width: 150px;
	height: 50px;
	color: #f89a44;
	background-color: #21325e;
	font-weight: bold;
	border-radius: 5px;
}
.deliveryFormContainer span {
	font-weight: bold;
	font-size: 40px;
	color: #21325e;
}
.deliveryFormContainer p {
	color: lightslategray;
	text-align: center;
	width: 85%;
	font-size: 12px;
	font-weight: bold;
}
.formContainer {
	width: 100%;
	max-width: 350px;
	height: 100%;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 15px;
	margin-bottom: 15px;
}
.smallloader {
	border: 5px solid #fefefe;
	border-radius: 50%;
	border-top: 5px solid #f1d00a;
	width: 30px;
	height: 30px;
	animation: spin 2s linear infinite;
}
.smallloadertxt {
	font-size: 12px;
}

.loader {
	border: 8px solid #fefefe;
	border-radius: 50%;
	border-top: 8px solid #f1d00a;
	width: 70px;
	height: 70px;
	animation: spin 2s linear infinite;
}
.lggloader {
	border: 12px solid #ffffff;
	border-radius: 50%;
	border-top: 12px solid #f89a44;
	width: 150px;
	height: 150px;
	animation: spin 2s linear infinite;
	position: absolute;
	top: 0px;
	left: 0px;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.formContainer .customForm {
	width: 100%;
	height: 400px;
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
	flex-direction: column;
	border: 1px solid #21325e;
	padding: 0px 20px;
	border-radius: 20px;
}
.formContainer .customForm div {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	width: 95%;
	max-width: 320px;
}
.formContainer .customForm div span {
	width: 100%;
	color: #21325e;
}
.whiteColor {
	color: white !important;
}
.errorDiv {
	width: 100%;
	padding: 5px 8px;
	text-align: center;
	background-color: red;
	color: white;
	border-radius: 10px;
}
.formContainer .customForm div input {
	border: 2px solid #21325e;
	color: #21325e;
	padding: 10px 10px;
	width: 100%;
	border-radius: 10px;
	margin-top: 15px;
	outline: 0px !important;
}
.formContainer .customForm button {
	padding: 10px 20px;
	background-color: #f1d00a;
	color: #21325e;
	font-weight: bold;
	border: none !important;
	outline: 0px !important;
	border-radius: 10px;
}
.formContainer span {
	font-size: 30px;
	font-weight: bold;
	width: 100%;
	margin-bottom: 20px;
	color: #21325e;
}
.showPreiewImage {
	object-fit: contain;
	width: 95%;
	max-width: 340px;
}
.showPreiewImage2 {
	object-fit: contain;
	width: 95%;
}
.firldsContainer {
	background: lightgray;
	min-height: 350px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
}
.activeLabel {
	width: 20px;
	height: 20px;
	border: 1px solid #21325e;
	background-color: #f89a44;
	border-radius: 2px;
	margin-right: 5px;
	cursor: pointer;
}
.notactive {
	border-radius: 2px;
	margin-right: 5px;
	cursor: pointer;
	width: 20px;
	height: 20px;
	border: 1px solid #21325e;
	background-color: white;
}
.firldsContainer .inputContainer {
	width: 100%;
	height: 50px;
	/* border: 2px solid #21325e; */
	border-radius: 5px;
	outline: none !important;
	color: #21325e;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	overflow: hidden;
}
.firldsContainer .inputContainer div {
	width: calc(100% - 60px);
	height: 50px;
	outline: none !important;
	border: 0px !important;
	background: transparent;
	padding: 0px 14px;
}
.firldsContainer .inputContainer .clearbtnFilter {
	width: 60px;
	background-color: white !important;
	height: 50px;
	border-radius: 5px;
	padding: 0px 14px;
	outline: none !important;
	border: 0px !important;
	cursor: pointer;
	color: #21325e;
}
.filterBox {
	width: 100%;
	padding: 20px 10px;
	background-color: white;
	border-radius: 20px;
	max-width: 250px;
	margin: 0px auto;
	height: 100%;
}
.filterBox label {
	width: 100%;
	margin-bottom: 10px;
	cursor: pointer;
}
.filterBox label input {
	margin-right: 10px;
}

.respoloront {
	background-color: white;
}
.cartimgrsp {
	max-width: 90px;
	height: 100%;
	max-height: 90px;
	width: 100%;
	object-fit: contain;
}
.langsele {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 0px;
	background-color: #f89a44 !important;
	padding: 0px 10px;
}
.responsiveFontBtn {
	max-width: 180px;
}
.extraservicesheadresp {
	font-size: 12px;
	font-weight: 600;
}
.contactCRD {
	width: 90%;
	margin: 10px auto;
	height: 150px;
	border: 1px solid white;
	background-color: white;
	border-radius: 10px;
}
.contactCRD:hover > svg {
	color: #f89a44;
}
.contactCRD:hover > span {
	color: #f89a44;
	font-weight: bold;
}
.borderadiusbtn {
	border-radius: 5px !important;
}
.contactCRD > svg {
	width: 100%;
	height: 70px;
	margin-bottom: 10px;
	color: #21325e;
}
.contactCRD > span {
	width: 100%;
	text-align: center;
	color: #21325e;
	font-size: 16px;
}
@media screen and (max-width: 768px) {
	.extraserviceheadresp {
		font-size: 14px;
		font-weight: 600;
	}
	.extraservicesheadresp {
		font-size: 10px;
		font-weight: 600;
	}
	.responsiveFontBtn {
		font-size: 0.8rem !important;
		padding: 0.7rem 0.5rem !important;
		font-weight: bold !important;
	}
	.respoloront {
		background-color: transparent !important;
		margin-top: 25px !important;
	}
	.cartimgrsp {
		max-width: none !important;
		height: 200px;
		max-height: none !important;
		width: 100%;
		object-fit: contain;
	}
	.showPreiewImage {
		height: 250px;
	}
	.showPreiewImage2 {
		height: 90%;
	}
	.responsiveQuestion {
		font-weight: bold;
		font-size: 16px;
		line-height: 18px;
		margin-top: 10px;
	}
	.responsiveQuestion2 {
		font-size: 12px;
	}
	.responsiveHeading {
		font-size: 20px;
	}
}
@media screen and (max-width: 320px) {
	.responsiveFontBtn {
		font-size: 0.62rem !important;
	}
	.navbar-brand img {
		height: 30px !important;
	}
	.responsiveQuestion {
		font-weight: bold;
		font-size: 16px;
		line-height: 18px;
		margin-top: 10px;
	}
	.responsiveQuestion2 {
		font-size: 12px;
		line-height: 30px;
	}
	.responsiveHeading {
		font-size: 20px;
	}
}
.customStylingPahment {
	background-color: red;
}
.requiredfilter {
	height: 100%;
	background-color: transparent !important;
	padding: 0.375rem 0px;
	flex-direction: row;
}
.requiredfilter label {
	text-transform: capitalize;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: #21325e;
}
.sameasshippinginp {
	padding: 12px;
	background-color: white;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	margin-bottom: 12px;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}
